import React, { useRef } from "react";
import AppBar from "../Components/Appbar/AppBar";
import Landing from "./Landing";
import About from "./About";
import Gallery from "./Gallery";
import Contact from "./Contact";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
// import MaintenanceBanner from "../Components/Maintenance/MaintenanceBanner";

export const MainLayout: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const paymentStatus = () => {
    if (queryParams.get("success") === "true") {
      return <div>Payment succeeded! Thank you for your purchase.</div>;
    }
    if (queryParams.get("canceled") === "true") {
      return <div>Payment was canceled.</div>;
    }
    return null;
  };

  function usePrevious(value) {
    const ref = useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const scrollToValue = location.state && location.state.scrollTo;
  const prevLocationState = usePrevious(scrollToValue);
  
  React.useEffect(() => {
      if (scrollToValue && scrollToValue !== prevLocationState) {
          const sectionElement = document.getElementById(scrollToValue);
          if (sectionElement) {
              sectionElement.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }, [scrollToValue]);
  

  return (
    <div>
      <AppBar />
      {/* <MaintenanceBanner /> */}

      {paymentStatus()}
      <Landing color="primary" />
      <About color="primary" />
      <Gallery />
      <Contact color="primary" />
      <Footer />
    </div>
  );
};
