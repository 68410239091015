import React, { useEffect, useState } from "react";
import { Grid, Card, CardMedia, CardContent, Typography } from "@mui/material";
import { Product } from "./ProductDetails";

interface GalleryProps {
  onSelectProduct: (product: Product) => void;
}

const url = REACT_APP_PRODUCT_API_ROUTE;

if (!url) {
  throw new Error("REACT_APP_PRODUCT_API_ROUTE must be defined");
}

export const ProductGallery: React.FC<GalleryProps> = ({ onSelectProduct }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [_error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        const productsList = JSON.parse(responseData.body);
        console.log(productsList); // this should log your array

        const transformedData = transformStripeData(productsList);
        setProducts(transformedData);
        console.log(transformedData);
      } catch (error: unknown) {
        // Explicitly state that error is of type unknown
        if (error instanceof Error) {
          // Check if error is an instance of Error
          console.error("Error fetching products:", error.message);
          setError(error.message);
        } else {
          console.error("An unknown error occurred:", error);
        }
      }
    };
    fetchProducts();
  }, []);

  return (
    <Grid container spacing={2}>
      {products.map((product) => (
        <Grid
          key={product.id}
          item
          xs={12}
          sm={6}
          md={4}
          onClick={() => onSelectProduct(product)}
        >
          <Card sx={{ cursor: "pointer" }}>
            <CardMedia
              component="img"
              height="auto"
              width="300"
              sx={{ objectFit: "contained" }}
              key={product.image}
              image={product.image}
              alt={product.title}
              loading="lazy"
            />
            <CardContent>
              <Typography
                key={product.title}
                variant="h6"
                sx={{ fontWeight: 300, letterSpacing: ".3rem" }}
              >
                {product.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

function transformStripeData(stripeData: any[]): Product[] {
  return stripeData.map((product) => ({
    ...product,
    sizes: product.prices.map((price) => ({
      size: getSizeFromPrice(price.amount), // A helper function to map price to size
      price: {
        id: price.id,
        amount: price.amount,
        currency: price.currency,
      },
    })),
  }));
}

function getSizeFromPrice(amount: number): string {
  // This is a basic example. Adjust based on your specific pricing.
  switch (amount) {
    case 2500:
      return "5x7";
    case 3500:
      return "8x10";
    case 5000:
      return "11x14";
    default:
      return "Unknown";
  }
}
