// App.tsx
import React, { useState } from "react";
import { ProductGallery } from "../Components/Products/ProductGallery";
import { Product, ProductDetails } from "../Components/Products/ProductDetails";
import { Container, Typography, Divider } from "@mui/material";


function Gallery() {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const handleSelectProduct = (product: Product) => {
    setSelectedProduct(product);
  };

  const handleCloseDetails = () => {
    setSelectedProduct(null);
  };

  return (
    <Container>
      <Typography
        id="shop"
        variant="h3"
        align="center"
        gutterBottom
        sx={{ pt: 10, fontWeight: 300, letterSpacing: ".3rem" }}
      >
        Scratchboards
      </Typography>
      <ProductGallery onSelectProduct={handleSelectProduct} />

      {selectedProduct && (
        <ProductDetails
          product={selectedProduct}
          onClose={handleCloseDetails}

        />
      )}

      <Divider style={{ margin: "20px 0" }} />
    </Container>
  );
}

export default Gallery;
