// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/slick-carousel/slick/slick.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/slick-carousel/slick/slick-theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
* {
  margin: 0;
  padding: 0;
  border: 0;
  overflow-x: hidden;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("https://fonts.cdnfonts.com/css/proxima-nova-condensed")
      format("woff2"),
    url("https://fonts.cdnfonts.com/css/proxima-nova-condensed") format("woff");
  font-weight: 400;
  font-style: normal;
}
.Toastify__toast {
  display: block !important;
  opacity: 1 !important;
  background-color: #93B7BE;
}
.Toastify__toast-container {
  z-index: 9999 !important;
}
.custom-toast {
  background: darkblue;
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":";AAIA;EACE,SAAS;EACT,UAAU;EACV,SAAS;EACT,kBAAkB;AACpB;AACA;EACE,2BAA2B;EAC3B;;+EAE6E;EAC7E,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,yBAAyB;AAC3B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,oBAAoB;EACpB,WAAW;EACX,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB","sourcesContent":["\n@import \"slick-carousel/slick/slick.css\";\n@import \"slick-carousel/slick/slick-theme.css\";\n\n* {\n  margin: 0;\n  padding: 0;\n  border: 0;\n  overflow-x: hidden;\n}\n@font-face {\n  font-family: \"Proxima Nova\";\n  src: url(\"https://fonts.cdnfonts.com/css/proxima-nova-condensed\")\n      format(\"woff2\"),\n    url(\"https://fonts.cdnfonts.com/css/proxima-nova-condensed\") format(\"woff\");\n  font-weight: 400;\n  font-style: normal;\n}\n.Toastify__toast {\n  display: block !important;\n  opacity: 1 !important;\n  background-color: #93B7BE;\n}\n.Toastify__toast-container {\n  z-index: 9999 !important;\n}\n.custom-toast {\n  background: darkblue;\n  color: #fff;\n  font-size: 20px;\n  width: 34vw;\n  padding: 30px 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
