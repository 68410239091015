import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Typography,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";
import { CartItem, useCart } from "../../Context/CartContext";
import { toast } from "react-toastify";
import theme from "../../Context/theme";
import "../../app.css";
export interface Price {
  id: string;
  amount: number;
  currency: string;
}

export interface Size {
  size: string;
}

export interface Product {
  id: string;
  title: string;
  sizes: Size[];
  prices: Price[];
  description: string;
  image: string;
  quantity: number;
  priceValue?: number;
  currency?: string;
  selectedSize?: Size;
}

interface ProductDetailsProps {
  product: Product;
  onClose: () => void;
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  product,
  onClose,
}) => {
  const [selectedSizes, setSelectedSizes] = useState<Size | null>(null);

  const [openFullScreen, setOpenFullScreen] = useState(false);

  const { addToCart } = useCart();

  //FIND INDEX OF ITEM AND THE SELECTED SIZE
  const sizeIndex = product.sizes.findIndex(
    (size) => size.size === selectedSizes?.size
  );
  const selectedPrice = product.prices[sizeIndex];

  //SORT SIZES
  const sizes = ["11x14", "5x7", "8x10"];

  const sortedSizes = sizes.sort((a, b) => {
    const [aWidth] = a.split("x").map(Number);
    const [bWidth] = b.split("x").map(Number);

    return aWidth - bWidth;
  });

  const handleOpenFullScreen = () => {
    setOpenFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: 300, letterSpacing: ".3rem" }}>
        {product.title}
      </DialogTitle>
      <DialogContent>
        <img
          src={product.image}
          alt={product.title}
          style={{ width: "100%" }}
          onClick={handleOpenFullScreen}
        />
        <Dialog
          fullScreen
          open={openFullScreen}
          onClose={handleCloseFullScreen}
        >
          <img
            src={product.image}
            alt={product.title}
            style={{ width: "100%", height: "100vh", objectFit: "contain" }}
            onClick={handleCloseFullScreen}
          />
        </Dialog>

        <Typography variant="body2">{product.description}</Typography>
        <FormControl fullWidth>
          <InputLabel htmlFor="size-select">Choose Size</InputLabel>
          <Select
            value={selectedSizes?.size || ""}
            onChange={(event) => {
              const sizeValue = event.target.value;
              const sizeOption = product.sizes.find(
                (s) => s.size === sizeValue
              );
              if (sizeOption) setSelectedSizes(sizeOption);
            }}
          >
            {product &&
              product.sizes &&
              sortedSizes.map((sizeOption) => (
                <MenuItem key={sizeOption} value={sizeOption}>
                  {sizeOption}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {selectedPrice && (
          <Typography
            variant="h6"
            style={{ marginTop: "10px", fontWeight: 500 }}
          >
            Price: $
            {`${
              selectedPrice.amount / 100
            } ${selectedPrice.currency.toUpperCase()}`}
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            if (selectedSizes && selectedPrice) {
              const cartItem: CartItem = {
                title: product.title,
                image: product.image,
                quantity: 1,
                selectedSize: selectedSizes,
                selectedPrice: selectedPrice,
              };

              console.log("Attempting to add product:", cartItem);
              addToCart(cartItem);

              // Display the toast notification here
              toast.success("Item added to cart!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "colored",
                style: {
                  backgroundColor: theme.palette.rose.light,
                  color: "#fff", // Assuming you want white text color
                },
                // Just to make sure, but it should inherit from ToastContainer
              });
            } else {
              console.log("Selected sizes or price not available"); // Log the scenario where the condition fails
            }
          }}
          color="primary"
        >
          Add to cart
        </Button>

        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
