// GlobalStyles.tsx
import React from "react";
import { css, Global } from "@emotion/react";

const globalStyles = css`

  @font-face {
    font-family: "Proxima Nova";
    src: url("https://fonts.cdnfonts.com/css/proxima-nova-condensed"),
      url("https://fonts.cdnfonts.com/css/proxima-nova-condensed")
        format("woff");
    font-weight: 400;
    font-style: normal;
  }

  body {
    font-family: "Quicksand", sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Proxima Nova", sans-serif;
  }
`;

const GlobalStyles = () => <Global styles={globalStyles} />;

export default GlobalStyles;
