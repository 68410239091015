import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import LogoOne from "../../../assets/LogoOne.png";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InfoIcon from "@mui/icons-material/Info"; // for About
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary"; // for Gallery
import ContactIcon from "@mui/icons-material/ContactPage";
import { Link, useLocation } from "react-router-dom";
import { useCart } from "../../Context/CartContext";
import { useNavigate } from 'react-router-dom';


interface Page {
  title: string;
  id: string;
  icon: JSX.Element;
}

type CartItemType = {};

export interface CartContextType {
  cartItems: CartItemType[];
}

const pages: Page[] = [
  {
    title: "About",
    id: "about",
    icon: (
        <InfoIcon
          sx={{ mr: 1, mt: 1, color: "rose.light", cursor: "pointer" }}
        />
    ),
  },
  {
    title: "Shop",
    id: "shop",
    icon: (
        <PhotoLibraryIcon
          sx={{ mr: 1, mt: 1, color: "rose.light", cursor: "pointer" }}
        />
    ),
  },
  {
    title: "Contact",
    id: "contact",
    icon: (
        <ContactIcon
          sx={{ mr: 1, mt: 1, color: "rose.light", cursor: "pointer" }}
        />
    ),
  },
];

// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [_anchorElUser, _setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  let navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  
  const handleScrollToSection = (sectionId: string) => {
    // If you're not on the main page
    if (pathname !== "/") {
        navigate("/", { state: { scrollTo: sectionId } });
    } else {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: "smooth" });
        }
    }
  };

  const { cart } = useCart();
  const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);



  //   const handleCloseUserMenu = () => {
  //     setAnchorElUser(null);
  //   };

  return (
    <AppBar position="fixed" sx={{ bgcolor: "lightBlue.light" }}>
      <Container maxWidth="xl" sx={{ color: "rose.dark" }}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                color: "rose.light",
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <Box key={page.id} style={{ cursor: "pointer" }}>
                  <MenuItem
                    onClick={() => handleScrollToSection(page.id)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "lightCyan.dark", // Change this to whatever color you want on hover.
                      },
                    }}
                  >
                    {page.icon}
                    <Typography
                      textAlign="center"
                      sx={{
                        fontWeight: 400,
                        letterSpacing: ".3rem",
                        color: "lightBlue.dark",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      {page.title}
                    </Typography>
                  </MenuItem>
                </Box>
              ))}
            </Menu>
          </Box>
          <Link to="/">
            <img
              src={LogoOne}
              height={35}
              onClick={handleScrollToTop}
              alt="The Alpine Studio Logo"
              style={{ cursor: "pointer", paddingRight: "10px" }}
            />
          </Link>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 400,
              letterSpacing: ".3rem",
              color: "lightBlue.dark",
              textDecoration: "none",
              fontSize: {
                xs: ".8rem",
                sm: "1.4rem",
                md: "1.6rem",
                lg: "1.8rem",
                xl: "1.10rem",
              },
            }}
          >
            The Alpine Studio
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.id}
                onClick={() => handleScrollToSection(page.id)}
                sx={{
                  my: 2,
                  color: "lightBlue.dark",
                  display: "block",
                  cursor: "pointer",
                  fontWeight: 400,
                  letterSpacing: ".3rem",
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Link to="/cart">
              <IconButton color="inherit" aria-label="Open cart">
              {totalItems > 0 && <span style={{fontSize: '18px', color: '#554348', paddingRight: '2px'}}>{totalItems}</span>}
                <ShoppingCartIcon style={{color: '#554348'}}/>
                
              </IconButton>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
