interface SliderImages {
  image: string;
}

const sliderData: SliderImages[] = [
  {
    image:
      'https://scratch-boards.s3.us-west-2.amazonaws.com/All+Eyes+on+You.avif'
  },
  {
    image:
      'https://scratch-boards.s3.us-west-2.amazonaws.com/Red+Fox.avif'
  },
  {
    image:
      'https://scratch-boards.s3.us-west-2.amazonaws.com/Great+Grey+Owl.avif'
  },
  {
    image:
      'https://scratch-boards.s3.us-west-2.amazonaws.com/Kestrel.avif'
  },
  {
    image:
      'https://scratch-boards.s3.us-west-2.amazonaws.com/Rams+Butting+Heads.avif'
  }
];

export default sliderData;
