import { CssBaseline } from "@mui/material";
import React from "react";
import { ThemeProvider } from "@emotion/react";
import GlobalStyles from "./Context/GlobalStyles";
import "./app.css";

import { Route, Routes } from "react-router-dom";
import { CartProvider } from "./Context/CartContext";
import { CartLayout } from "./Pages/CartLouyout";
import { MainLayout } from "./Pages/MainLayout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "./Context/theme";
import PaymentStatus from "./Pages/PaymentStatus";
import Checkout from "./Components/Cart/Checkout";

let key = REACT_APP_PUBLIC_KEY;

if (!key) {
  throw new Error("REACT_APP_PUBLIC_KEY must be defined");
}

const stripePromise = loadStripe(key);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={theme}>
        <ToastContainer position="bottom-left" />
        <CssBaseline enableColorScheme>
          <CartProvider>
            <GlobalStyles />

            <Routes>
              <Route path="/cart" element={<CartLayout />} />
              <Route path="/success" element={<PaymentStatus />} />
              <Route path="/canceled" element={<PaymentStatus canceled />} />
              <Route path="/checkout" element={<Checkout />} />

              <Route path="/" element={<MainLayout />} />
              <Route path="/about" element={<MainLayout />} />
              <Route path="/shop" element={<MainLayout />} />
              <Route path="/contact" element={<MainLayout />} />
            </Routes>
          </CartProvider>
        </CssBaseline>
      </ThemeProvider>
    </Elements>
  );
}
export default App;
