import { Box, Grid, Typography } from "@mui/material";
import Wizard from "../Components/Wizard/ContactWizard";
import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

const Contact = ({ color }: { color: "primary" | "secondary" }) => {
  const handleOpacityChange = (currentPosition: any, className: string) => {
    const element = document.querySelector(className) as HTMLElement;
    if (
      element &&
      currentPosition &&
      currentPosition.x &&
      currentPosition.x[0] <= 0
    ) {
      const opacityValue = 1 + currentPosition.x[0] / 100;
      element.style.opacity = opacityValue.toString();
    }
  };

  return (
    <Grid
      id="contact"
      sx={{
        width: "100%",
        pb: "10vh",
        height: "40%",
        bgcolor: "lightBlue.light",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <ParallaxProvider>
        <Box
          sx={{
            overflow: "hidden",
            flex: "1",
            maxWidth: { xs: "90%", sm: "50%" },
            m: { xs: 1, sm: 2 },
          }}
        >
          <Parallax
            speed={5}
            translateY={[100, -100]}
            onChange={(props: any) =>
              handleOpacityChange(props.currentPosition, ".alpine-text")
            }
          >
            <Typography
              variant="h3"
              className="contact-text-title"
              sx={{
                color: "grayCadet.light",
                opacity: 1,
                transition: "opacity 1s",
                fontWeight: 300,
                letterSpacing: ".3rem",
              }}
            >
              Contact
            </Typography>
            <br />
            <Typography
              variant="h6"
              className="contact-text-subtitle"
              sx={{
                fontWeight: 400,
                letterSpacing: ".3rem",
                color: "grayCadet.light",
                opacity: 1,
                transition: "opacity 1s",
              }}
            >
              Looking for a custom scratchboard?
            </Typography>
            {/* <Typography
              variant="body2"
              className="contact-text-content"
              sx={{
                pt: 1,
                fontWeight: 400,
                letterSpacing: ".2rem",
                color: "grayCadet.light",
                opacity: 1,
                transition: "opacity 1s",
              }}
            >
              All scatchboards are
            </Typography> */}
            <Box sx={{ justifyItems: "flex-end", pt: 1 }}>
              <Typography
                variant="h6"
                className="alpine-text"
                sx={{
                  letterSpacing: ".1rem",
                  color: "rose.dark",
                  opacity: 1,
                  transition: "opacity 1s",
                  fontWeight: 300,
                }}
              >
                Select an animal <br />
                <Wizard />
              </Typography>
            </Box>
            <Box sx={{ justifyContent: "flex-end", alignItems: "center" }}>
              <Typography
                variant="h4"
                color="rose.dark"
                sx={{ pb: 10, pt: 6 }}
              >
              </Typography>

              
            </Box>
          </Parallax>
        </Box>
      </ParallaxProvider>
    </Grid>
  );
};

export default Contact;
