import { createTheme } from '@mui/material/styles';

// Augment the palette to include a lightBlue color
declare module '@mui/material/styles' {
    interface Palette {
      lightBlue: Palette['primary'];
      rose: Palette['primary'];
      lightCyan: Palette['primary'];
      cadetGray: Palette['primary'];
      gray: Palette['primary'];
    }
  
    interface PaletteOptions {
      lightBlue?: PaletteOptions['primary'];
      rose?: PaletteOptions['primary'];
      lightCyan?: PaletteOptions['primary'];
      cadetGray?: PaletteOptions['primary'];
      gray?: PaletteOptions['primary'];
    }
  }
  
  // Update the Button's color options to include a lightBlue option
  declare module '@mui/material/Typography' {
    interface TextPropsColorOverrides {
      lightBlue: true;
      rose: true;
      lightCyan: true;
      cadetGray: true;
      gray: true
    }
  }

  let theme = createTheme({
    // Theme customization goes here as usual, including tonalOffset and/or
    // contrastThreshold as the augmentColor() function relies on these
  });
  
theme = createTheme({
    palette: {
        lightBlue: theme.palette.augmentColor({
          color: {
            main: '#93B7BE',
          },
          name: 'lightBlue',
        }),
        rose: theme.palette.augmentColor({
            color: {
              main: '#554348', // Example rose color
            },
            name: 'rose',
          }),
          lightCyan: theme.palette.augmentColor({
            color: {
              main: '#D4F5F5', // Example lightCyan color
            },
            name: 'lightCyan',
          }),
          cadetGray: theme.palette.augmentColor({
            color: {
              main: '#8C9A9E', // Example lightCyan color
            },
            name: 'cadetGray',
          }),
          gray: theme.palette.augmentColor({
            color: {
              main: '#747578', // Example lightCyan color
            },
            name: 'gray',
          }),
      },
  });
  
export default theme

