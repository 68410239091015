import { Box, IconButton, Typography } from "@mui/material";
import ArielSig from "../Components/Signature/ArielSig";
import React from "react";
import sliderData from "../Components/Carousel/sliderData";
import Sliders from "../Components/Carousel/Slider";
import InstagramIcon from "@mui/icons-material/Instagram";
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Landing = ({ color }: { color: "primary" | "secondary" }) => {
  return (
    <div id="/">
      <Box
        sx={{
          width: "100vw",
          height: "auto",
          bgcolor: "rose.light",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          pb: 10,
          pt: 10,
        }}
      >
        <ArielSig />
        <Typography
          variant="h1"
          sx={{
            pt: 5,
            letterSpacing: ".3rem",
            fontSize: {
              xs: "2.8rem",
              sm: "3rem",
              md: "3.4rem",
              lg: "3.8rem",
              xl: "4rem",
            },
          }}
        >
          The Alpine Studio
        </Typography>

        <Typography
          variant="h4"
          sx={{
            pt: 2,
            pb: 2,
            color: "lightBlue.light",
            letterSpacing: ".3rem",
            fontWeight: 300,
            fontSize: {
              xs: "1.2rem",
              sm: "1.4rem",
              md: "1.6rem",
              lg: "1.8rem",
              xl: "1.10rem",
            },
          }}
        >
          Wilderness Wonders, Detailed by Hand
        </Typography>
        <IconButton
          component="a"
          href="https://www.instagram.com/alpine.studio.art/"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <InstagramIcon />
        </IconButton>
      </Box>
      <Sliders sliderData={sliderData} />
    </div>
  );
};

export default Landing;
