import { Box, Typography } from "@mui/material";
import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import ariel from "../../assets/framed-Ariel.png";

const About = ({ color }: { color: "primary" | "secondary" }) => {
  const handleOpacityChange = (currentPosition: any, className: string) => {
    const element = document.querySelector(className) as HTMLElement;
    if (
      element &&
      currentPosition &&
      currentPosition.x &&
      currentPosition.x[0] <= 0
    ) {
      const opacityValue = 1 + currentPosition.x[0] / 100;
      element.style.opacity = opacityValue.toString();
    }
  };

  return (
    <Box
    id="about"
      sx={{
        width: "100%",
        pb: "10vh",
        height: "70%",
        bgcolor: "rose.light",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <ParallaxProvider >
        <Box
        
          sx={{
            overflow: "hidden",
            flex: "1",
            maxWidth: { xs: "90%", sm: "50%" },
            m: { xs: 1, sm: 2 },
          }}
        >
          <Parallax
          
            speed={5}
            translateY={[100, -100]}
            onChange={(props: any) =>
              handleOpacityChange(props.currentPosition, ".alpine-text")
            }
          >
            <Typography
            
              variant="h4"
              className="alpine-text"
              sx={{
                color: "grayCadet.light",
                opacity: 1,
                transition: "opacity 1s",
                fontWeight: 300,
                letterSpacing: ".3rem",
              }}
            >
              The Artist
            </Typography>
            <Typography
              variant="body1"
              className="alpine-text"
              sx={{
                letterSpacing: ".1rem",
                color: "lightBlue.light",
                opacity: 1,
                transition: "opacity 1s",
                fontWeight: 300,
              }}
            >
              Ariel Rodriguez is a scratchboard artist that combines a patient
              mind with creative impulsion while grasping the depth and
              integrity of Yellowstone wildlife. With the help of an x-acto
              knife, Ariel applies pressure to a scratchboard to bring an image
              to life.
            </Typography>
            <br />
            <Typography
              variant="body1"
              className="wilderness-text"
              sx={{
                letterSpacing: ".1rem",
                color: "lightBlue.light",
                opacity: 1,
                transition: "opacity 1s",
                fontWeight: 300,
              }}
            >
              Every scratch must find its home in perfect fashion for the
              subject to hold an aspect of realism. Following a similar style to
              tattoo, scratchboard artists must be able to puzzle piece an image
              together using all individual scratches. Ariel is extremely
              dedicated to attention to detail making sure all feathers, hairs,
              fur and eyes are methodically placed without question. She
              inspires curiosity in younger generations as she works on
              scratchboards in front of a live audience, inviting them to come
              up close and see the medium's appeal before completion.
            </Typography>
            <br />
            <Typography
              variant="h4"
              className="alpine-text"
              sx={{
                letterSpacing: ".3rem",
                color: "grayCadet.light",
                opacity: 1,
                transition: "opacity 1s",
                fontWeight: 300,
              }}
            >
              What is a Scratchboard
            </Typography>
            <Typography
              variant="body1"
              className="alpine-text"
              sx={{
                letterSpacing: ".1rem",
                color: "lightBlue.light",
                opacity: 1,
                transition: "opacity 1s",
                fontWeight: 300,
              }}
            >
              Scratchboards are white, clay boards that have been painted with a
              light layer of India Black ink. Depending on how much pressure is
              applied to the board through the knife tip, the scratchboard will
              be a lighter or darker shade of white. While some scratch boards
              are black and white, some color can be added with different
              pigments of India ink and a soft paint brush. This is a tedious,
              unforgiving process and leaves no room for error.
            </Typography>
            <br />
            <Typography
              variant="h4"
              className="alpine-text"
              sx={{
                fontWeight: 300,
                letterSpacing: ".3rem",
                color: "grayCadet.light",
                opacity: 1,
                transition: "opacity 1s",
              }}
            >
              Where can these be found?
            </Typography>
            <Typography
              variant="body1"
              className="alpine-text"
              sx={{
                letterSpacing: ".1rem",
                color: "lightBlue.light",
                opacity: 1,
                transition: "opacity 1s",
                fontWeight: 300,
              }}
            >
              These art pieces can be found in gift shops at Yellowstone
              National Park, Alpine Honey in Livingston (MT), and Wonderland
              Cafe and Lodge in Gardiner (MT)
            </Typography>
          </Parallax>
        </Box>
        <Box
          sx={{
            overflow: "hidden",
            flex: "1",
            maxWidth: { xs: "100%", sm: "50%" },
            m: { xs: 1, sm: 2 },
          }}
        >
          <Parallax speed={5} translateY={[-100, 100]}>
            <img src={ariel} alt="card-port" width="400" height="600" />
          </Parallax>
        </Box>
      </ParallaxProvider>
    </Box>
  );
};

export default About;
