import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grid from "@mui/material/Grid";

const currentYear = new Date().getFullYear();

function Footer() {
  return (
    <Box component="footer" sx={{ bgcolor: "rose.light", py: { xs: 4, sm: 3 }, mt: 5 }}>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm="auto">
            <Typography
              color="lightBlue.light"
              variant="body2"
              sx={{ fontWeight: 300, letterSpacing: ".3rem", textAlign: { xs: 'center', sm: 'left' } }}
            >
              © {currentYear} The Alpine Studio |
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography
              color="lightBlue.light"
              variant="body2"
              sx={{ fontWeight: 300, letterSpacing: ".3rem", textAlign: { xs: 'center', sm: 'left' } }}
            >
              Designed by TJ Prohammer
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <IconButton
              component="a"
              href="https://www.instagram.com/alpine.studio.art/"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <InstagramIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
