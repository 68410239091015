import React, { useState } from 'react';
import { useStripe, useElements, CardElement, Elements, AddressElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import {
  Button,
  CircularProgress,
  Typography,
  Grid,
  Divider,
  Box,
  Card,
  CardContent,
  TextField,
  ListItem,
  List,
  ListItemText,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { CartItem, useCart } from '../../Context/CartContext';

const stripePromise = loadStripe(REACT_APP_PUBLIC_KEY);

const url = REACT_APP_CART_API_ROUTE;

if (!url) {
  throw new Error('REACT_APP_CART_API_ROUTE must be defined');
}

const formatCartForAPI = (cart: CartItem[]): any[] => {
  return cart.map((item) => ({
    id: item.selectedPrice.id,
    name: item.title,
    size: item.selectedSize.size,
    currency: item.selectedPrice.currency,
    quantity: item.quantity,
    price: item.selectedPrice.amount,
  }));
};

const CheckoutForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { cart } = useCart();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [useSameAddress, setUseSameAddress] = useState(true);

  // User details state
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    // Validate user inputs
    if (!name || !email) {
      setError('Please fill in all the required fields.');
      setLoading(false);
      return;
    }

    const addressElement = elements.getElement(AddressElement);

    if (!addressElement) {
      setError('Address element is not available.');
      setLoading(false);
      return;
    }

    const { value: shippingAddress } = await addressElement.getValue();
    let billingAddress = shippingAddress;

    if (!useSameAddress) {
      const billingAddressElement = elements.getElement(AddressElement);
      if (!billingAddressElement) {
        setError('Billing address element is not available.');
        setLoading(false);
        return;
      }
      const { value } = await billingAddressElement.getValue();
      billingAddress = value;
    }

    const formattedCart = formatCartForAPI(cart);

    try {
      const response = await axios.post(url, {
        items: formattedCart,
        customer: {
          name,
          email,
          shippingAddress,
          billingAddress,
        },
      });

      if (!response.data.sessionId) {
        setError('Unable to create Stripe session.');
        setLoading(false);
        return;
      }

      const { sessionId } = response.data;

      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        setError(result.error.message || 'Redirect to Stripe failed');
      }
    } catch (error) {
      setError('An error occurred while processing the checkout.');
    } finally {
      setLoading(false);
    }
  };

  const totalPrice = cart.reduce(
    (acc, item) => acc + (item.selectedPrice.amount * item.quantity || 0),
    0
  );

  return (
    <Box
      sx={{
        width: "100%",
        pb: "10vh",
        height: "70%",
        bgcolor: "lightCyan.main",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        color: "gray.main",
      }}
    >
      <Card variant="outlined" sx={{ mt: 3, p: 2, maxWidth: 600, width: '100%', bgcolor: 'rose.main' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 300, letterSpacing: '.3rem', color: 'lightBlue.main' }}>
            Checkout
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Name"
                  fullWidth
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ bgcolor: "lightCyan.main" }}
                  InputLabelProps={{
                    style: { color: 'gray.main' },
                  }}
                  InputProps={{
                    style: { color: 'gray.main' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Email"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ bgcolor: "lightCyan.main" }}
                  InputLabelProps={{
                    style: { color: 'gray.main' },
                  }}
                  InputProps={{
                    style: { color: 'gray.main' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ color: 'lightBlue.main' }}>
                  Shipping Address
                </Typography>
                <Box
                  sx={{
                    bgcolor: 'lightCyan.main',
                    border: '1px solid gray',
                    borderRadius: '4px',
                    padding: '10px',
                  }}
                >
                  <AddressElement options={{ mode: 'shipping' }} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={useSameAddress} onChange={() => setUseSameAddress(!useSameAddress)} />}
                  label="Billing address is the same as shipping address"
                  sx={{ color: 'gray.main' }}
                />
              </Grid>
              {!useSameAddress && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom sx={{ color: 'lightBlue.main' }}>
                    Billing Address
                  </Typography>
                  <Box
                    sx={{
                      bgcolor: 'lightCyan.main',
                      border: '1px solid gray',
                      borderRadius: '4px',
                      padding: '10px',
                    }}
                  >
                    <AddressElement options={{ mode: 'billing' }} />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ color: 'lightBlue.main' }}>
                  Order Summary
                </Typography>
                <List>
                  {cart.map((item) => (
                    <ListItem key={item.selectedPrice.id}>
                      <ListItemText
                        primary={item.title}
                        secondary={`Size: ${item.selectedSize.size}, Quantity: ${item.quantity}, Price: $${(item.selectedPrice.amount * item.quantity) / 100}`}
                        primaryTypographyProps={{ color: 'gray.main' }}
                        secondaryTypographyProps={{ color: 'gray.main' }}
                      />
                    </ListItem>
                  ))}
                </List>
                <Divider sx={{ borderColor: 'gray.main' }} />
                <Typography variant="h6" gutterBottom sx={{ mt: 2, color: 'lightBlue.main' }}>
                  Total: ${totalPrice / 100} {cart[0]?.selectedPrice.currency}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    bgcolor: 'lightCyan.main',
                    border: '1px solid gray',
                    borderRadius: '4px',
                    padding: '10px',
                  }}
                >
                  <CardElement options={{ style: { base: { color: 'gray.main' } } }} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" fullWidth disabled={loading} sx={{ bgcolor: 'lightBlue.main', color: 'gray.main' }}>
                  {loading ? <CircularProgress size={24} /> : `Pay $${(totalPrice / 100).toFixed(2)}`}
                </Button>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="error">
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

const Checkout: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default Checkout;
