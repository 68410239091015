import { Size } from "Components/Products/ProductDetails";
import React, { createContext, useContext, useEffect, useState } from "react";

interface CartContextType {
  cart: CartItem[];
  addToCart: (item: CartItem) => void;
  removeFromCart: (productId: string) => void;
  clearCart: () => void;
  incrementQuantity: (productId: string) => void;
  decrementQuantity: (productId: string) => void;
}

// CartContext.tsx
export interface CartItem {
  title: string;
  image: string;
  currency?: number;
  amount?: number;
  priceValue?: number;
  selectedSize: Size;
  quantity: number;
  selectedPrice: {
    id: string;
    amount: number;
    currency: string;
  };
}

// rest of your CartContext code


interface CartProviderProps {
  children: React.ReactNode;
}

const updateCartInLocalStorage = (cart: CartItem[]) => {
  const cartJSON = JSON.stringify(cart);
  localStorage.setItem("userCart", cartJSON);
};

const getCartFromLocalStorage = (): CartItem[] => {
  const cartJSON = localStorage.getItem("userCart");
  return cartJSON ? JSON.parse(cartJSON) : [];
};

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider = ({ children }: CartProviderProps) => {
  const [cart, setCart] = useState<CartItem[]>(getCartFromLocalStorage());

  useEffect(() => {
    updateCartInLocalStorage(cart);
  }, [cart]);

  const addToCart = (item: CartItem) => {
    setCart((prev) => {
      const existingItem = prev.find(
        (p) =>
          p.title === item.title &&
          p.selectedSize.size === item.selectedSize.size
      );

      if (existingItem) {
        // Return a new array where the existing item's quantity is incremented
        return prev.map((p) =>
          p.selectedPrice.id === existingItem.selectedPrice.id
            ? { ...p, quantity: p.quantity + 1 }
            : p
        );
      } else {
        // Add the new item with quantity set to 1
        return [...prev, item];
      }
    });
  };

  const removeFromCart = (productId: string) => {
    setCart((prev) =>
      prev.filter((product) => product.selectedPrice.id !== productId)
    );
  };

  const clearCart = () => {
    setCart([]);
  };

  const incrementQuantity = (productId: string) => {
    setCart((prev) => {
      return prev.map((item) =>
        item.selectedPrice.id === productId
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
    });
  };

  const decrementQuantity = (productId: string) => {
    setCart((prev) => {
      return prev.map((item) =>
        item.selectedPrice.id === productId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
    });
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        incrementQuantity,
        decrementQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must ve used with CartProvider");
  }
  return context;
};
