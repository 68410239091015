import React, { useState } from "react";
import { CartItem, useCart } from "../../Context/CartContext";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { loadStripe } from "@stripe/stripe-js";

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(REACT_APP_PUBLIC_KEY);

const url = REACT_APP_CART_API_ROUTE;

if (!url) {
  throw new Error('REACT_APP_CART_API_ROUTE must be defined');
}

export const calculateTotal = (cart: CartItem[]): number => {
  return cart.reduce((sum, item) => sum + (item.priceValue || 0), 0);
};

const formatCartForAPI = (cart: CartItem[]): any[] => {
  return cart.map((item) => {
    return {
      id: item.selectedPrice.id,
      name: item.title,
      size: item.selectedSize.size,
      currency: item.selectedPrice.currency,
      quantity: item.quantity,
      price: item.selectedPrice.amount,
    };
  });
};

export const Cart: React.FC = () => {
  const {
    cart,
    removeFromCart,
    clearCart,
    incrementQuantity,
    decrementQuantity,
  } = useCart();
  const [_loading, setLoading] = useState(false);
  const [_error, setError] = useState<string | null>(null);
  const formattedCart = formatCartForAPI(cart);

  const initateCheckout = async () => {
    setError(null);
    setLoading(true);

    try {
      const itemsPayload = { items: formattedCart };
      const requestBody = JSON.stringify({ body: JSON.stringify(itemsPayload) });
      console.log("Request body to backend:", requestBody); // Log the request body

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      });

      if (!response.ok) {
        const message = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${message}`);
      }

      const responseBody = await response.json();
      console.log("Raw response body from backend:", responseBody);

      const data = JSON.parse(responseBody.body);
      console.log("Parsed response data from backend:", data);

      if (data.sessionId) {
        const stripe = await stripePromise;
        if (stripe) {
          const { error } = await stripe.redirectToCheckout({
            sessionId: data.sessionId,
          });
          if (error) {
            setError(error.message || null);
            console.error("Stripe redirect error:", error);
          }
        } else {
          console.error("Stripe has not loaded");
          setError("Stripe has not loaded");
        }
      } else {
        setError("Session ID is missing.");
        console.error("Session ID is missing in the response:", data);
      }
    } catch (error) {
      console.error("Error during checkout process:", error);
      setError("An Error occurred during the checkout process");
    } finally {
      setLoading(false);
    }
  };

  const totalPrice = cart.reduce(
    (acc, item) => acc + (item.selectedPrice.amount * item.quantity || 0),
    0
  );

  console.log("Cart Items: ", cart);
  return (
    <Card variant="outlined" sx={{ mt: 3, p: 2 }}>
      <CardContent sx={{ pt: 10 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 500, letterSpacing: ".3rem" }}
        >
          Cart
        </Typography>

        {cart.length > 0 ? (
          <List>
            {cart.map((product) => (
              <ListItem key={`${product.title}-${product.selectedSize.size}`}>
                <div>
                  <Button
                    onClick={() => incrementQuantity(product.selectedPrice.id)}
                  >
                    +
                  </Button>
                  {product.quantity}
                  <Button
                    onClick={() => decrementQuantity(product.selectedPrice.id)}
                  >
                    -
                  </Button>
                </div>

                <img
                  src={product.image}
                  alt={product.title}
                  width="200"
                  height="200"
                  style={{ objectFit: "contain", marginRight: "6px" }}
                />
                <ListItemText
                  sx={{ fontWeight: 300, letterSpacing: ".3rem" }}
                  primary={product.title}
                  secondary={`Size: ${
                    product.selectedSize?.size || "N/A"
                  } | Quantity: ${product.quantity} | Price: $${
                    (product.selectedPrice.amount * product.quantity || 0) / 100
                  } `}
                />
                <IconButton
                  edge="end"
                  onClick={() => removeFromCart(product.selectedPrice.id)}
                  color="error"
                  aria-label="remove from cart"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Cart is currently empty.
          </Typography>
        )}

        {cart.length > 0 && (
          <>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Total: ${totalPrice / 100} {cart[0].priceValue}{" "}
              {/* Display the currency of the first item, assuming all products have the same currency */}
            </Typography>
            <Button
              onClick={clearCart}
              color="secondary"
              variant="contained"
              sx={{ mt: 2, mr: 2 }}
            >
              Clear Cart
            </Button>
            {/* Add a button for checkout */}
            <Button
              type="button"
              color="primary"
              variant="contained"
              sx={{ mt: 2 }}
              onClick={initateCheckout}
            >
              Checkout
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  );
};
