import React from 'react';
import Slider from 'react-slick';
import './Slider.css';


interface Slide {
  image: string;
}

interface SlidersProps {
  sliderData: Slide[];
}

interface ArrowProps {
  currentSlide?: number;
  slideCount?: number;
  className?: string;
  onClick?: () => void;
  [key: string]: any;
}

const Sliders: React.FC<SlidersProps> = ({ sliderData }) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 580);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 780);
    };
    
    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  if (!Array.isArray(sliderData) || sliderData.length <= 0) {
    return null;
  }

  const GalleryPrevArrow: React.FC<ArrowProps> = ({ currentSlide, slideCount, ...props }) => {
    const { onClick } = props;

    return (
      <div {...props} className="custom-prevArrow" onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        </svg>
      </div>
    );
  };

  const GalleryNextArrow: React.FC<ArrowProps> = ({ currentSlide, slideCount, ...props }) => {
    
    const { onClick } = props;

    return (
      <div {...props} className="custom-nextArrow" onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
        </svg>
      </div>
    );
  };


  const settings = {
    className: 'center',
    centerMode: true,
    focusOnSelect: true,
    infinite: true,
    centerPadding: '100px',
    slidesToShow: isMobile ? 1 : 3,
    speed: 500,
    nextArrow: <GalleryNextArrow />,
    prevArrow: <GalleryPrevArrow />
  };

  return (
    <Slider {...settings}>
      {sliderData.map((slide, index) => {
        return (
          <div key={index}>
            <img src={slide.image} alt="slider" key={index} className="image" />
          </div>
        );
      })}
    </Slider>
  );
};

export default Sliders;
