import { Box, Typography } from "@mui/material";
import ResponsiveAppBar from "../Components/Appbar/AppBar";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import { useCart } from "../Context/CartContext"; 


function PaymentStatus({ canceled }: { canceled?: boolean }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { clearCart } = useCart();

  const hasClearedCartRef = useRef(false);
  
  React.useEffect(() => {
    if (queryParams.get("success") === "true" && !hasClearedCartRef.current) {
      clearCart();
      hasClearedCartRef.current = true;
    }
  }, [queryParams]);
  

  if (queryParams.get("success") === "true") {
    return (
      <>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ResponsiveAppBar />
          <Typography
            variant="h4"
            className="alpine-text"
            sx={{
              color: "grayCadet.light",
              height: "74vh",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              pt: 10,
              transition: "opacity 1s",
              fontWeight: 300,
              letterSpacing: ".3rem",
            }}
          >
            Payment succeeded! Thank you for your purchase.
          </Typography>
        </Box>
        <Footer />
      </>
    );
  }

  if (canceled && queryParams.get("canceled") === "true") {
    return (
      <Box
        sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}
      >
        <ResponsiveAppBar />
        <Typography
          variant="h4"
          className="alpine-text"
          sx={{
            color: "grayCadet.light",
            height: "74vh",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            pt: 10,
            transition: "opacity 1s",
            fontWeight: 300,
            letterSpacing: ".3rem",
          }}
        >
          Order Canceled!
        </Typography>
      </Box>
    );
  }

  return null;
}

export default PaymentStatus;
